import React from "react"
import Default from "../layouts/default"

const PageInfo = {
    desc: "Pension in zentraler Lage direkt am Markt mit modern eingerichteten Zimmern und Ferienwohnungen für 1 – 4 Personen",
    keywords: "übernachtung, pension, erzgebirge, datenschutz, zschopau, hotel, ferienwohnung, zur altdeutschen, einzelzimmer, doppelzimmer",
    title: 'Datenschutz – Pension "Zur Altdeutschen" Zschopau/Erzgebirge'
}

const Datenschutz = () => <Default pageInfo={PageInfo} headerImg="/assets/img/IMG_20131209_120147.jpg">
    <div>
        <h1>Datenschutzerklärung</h1>

        <p>Uns ist der Schutz Ihrer Daten ein wichtiges Anliegen. Wir setzen keine Technologie ein, welche das Verhalten der Besucher dieser Website verfolgt, oder diese profiliert. Es werden keine Cookies gespeichert.
        </p>
        <h2>
            Benennung der verantwortlichen Stelle
        </h2>

        <p>Die verantwortliche Stelle für die Datenverarbeitung auf der Website der Pension "Zur Altdeutschen" ist:<br />
        <br />
        Pension "Zur Altdeutschen"<br />
        Eva Schönstein<br />
        Neumarkt 1<br />
        09405 Zschopau<br /></p>

        <h2>Allgemeine Hinweise</h2>
        <p>In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.
        </p>
        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
        </p>
        <p>Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäss der nachfolgenden Beschreibung einverstanden. Diese Website kann grundsätzlich ohne Registrierung besucht werden. Dabei     werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden soweit möglich auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
        </p>

        <h2>Bearbeitung von Personendaten</h2>
        <p>Personendaten sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person beziehen. Eine betroffene Person ist eine Person, über die Personendaten bearbeitet werden. Bearbeiten umfasst jeden Umgang mit Personendaten, unabhängig von den angewandten Mitteln und Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen, Löschen, Speichern, Verändern, Vernichten und Verwenden von Personendaten.</p>

        <p>Wir bearbeiten Personendaten im Einklang mit dem Datenschutzrecht. Im Übrigen bearbeiten wir – soweit und sofern die EU-DSGVO anwendbar ist – Personendaten gemäss folgenden Rechtsgrundlagen im Zusammenhang mit Art. 6 Abs. 1 DSGVO:

        lit. a) Bearbeitung von Personendaten mit Einwilligung der betroffenen Person.
        lit. b) Bearbeitung von Personendaten zur Erfüllung eines Vertrages mit der betroffenen Person sowie zur Durchführung entsprechender vorvertraglicher Massnahmen.
        lit. c) Bearbeitung von Personendaten zur Erfüllung einer rechtlichen Verpflichtung, der wir gemäss allenfalls anwendbarem Recht der EU oder gemäss allenfalls anwendbarem Recht eines Landes, in dem die DSGVO ganz oder teilweise anwendbar ist, unterliegen.
        lit. d) Bearbeitung von Personendaten um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen.
        lit. f) Bearbeitung von Personendaten um die berechtigten Interessen von uns oder von Dritten zu wahren, sofern nicht die Grundfreiheiten und Grundrechte sowie Interessen der betroffenen Person überwiegen. Berechtigte Interessen sind insbesondere unser betriebswirtschaftliches Interesse, unsere Website bereitstellen zu können, die Informationssicherheit, die Durchsetzung von eigenen rechtlichen Ansprüchen und die Einhaltung von schweizerischem Recht.
        Wir bearbeiten Personendaten für jene Dauer, die für den jeweiligen Zweck oder die jeweiligen Zwecke erforderlich ist. Bei länger dauernden Aufbewahrungspflichten aufgrund von gesetzlichen und sonstigen Pflichten, denen wir unterliegen, schränken wir die Bearbeitung entsprechend ein.</p>

        
        
        <h2>
            SSL-/TLS-Verschlüsselung
        </h2>

        <p>
            Diese Website nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als Seitenbesucher senden, eine SSL-/TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>Wenn die SSL bzw. TLS Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>

        <h2>
            Datenschutzerklärung für Server-Log-Files
        </h2>

        <p>
            Der Provider dieser Website erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>

        <ul><li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
        </ul>
        <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zuprüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>


        <h2>
            Rechte betroffener Personen
        </h2>

        <h3>Recht auf Bestätigung</h3>

        <p>Jede betroffene Person hat das Recht, vom Betreiber der Website eine Bestätigung darüber zu verlangen, ob betroffene Personen betreffende, personenbezogene Daten verarbeitet werden. Möchten Sie dieses Bestätigungsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit an den Datenschutzbeauftragten wenden.</p>


        <h3>Recht auf Auskunft</h3>

        <p>Jede von der Verarbeitung betroffene Person mit personenbezogenen Daten hat das Recht, jederzeit vom Betreiber dieser Website unentgeltliche Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner kann gegebenenfalls über folgende Informationen Auskunft gegeben werden:</p>
        <ul>
            <li>die Verarbeitungszwecke</li>
            <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
            <li>die Empfänger, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden</li>
            <li>falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
            </li>das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung
            <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
            <li>wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten</li>
        </ul>
<p>Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person im übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der übermittlung zu erhalten.</p>

<p>Möchten Sie dieses Auskunftsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit an unseren Datenschutzbeauftragten wenden.</p>


<h3>Recht auf Berichtigung</h3>

<p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten - auch mittels einer ergänzenden Erklärung - zu verlangen.
</p>
<p>Möchten Sie dieses Berichtigungsrecht in Anspruch nehmen, können Sie sich hierzu jederzeit an unseren Datenschutzbeauftragten wenden.
</p>

<h3>Recht auf Löschung (Recht auf Vergessen werden)</h3>

<p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen dieser Website zu verlangen, dass die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:</p>

<ul> 
    <li>Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie nicht mehr notwendig sind</li>     
    <li>Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung</li>    
    <li> Die betroffene Person legt aus Gründen, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt im Falle von Direktwerbung und damit verbundenem Profiling Widerspruch gegen die Verarbeitung ein</li>  
    <li>Die personenbezogenen Daten wurden unrechtmässig verarbeitet</li>    
    <li>Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt</li>     
    <li>Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft, die einem Kind direkt gemacht wurden, erhoben</li>
</ul>  
<p>Sofern einer der oben genannten Gründe zutrifft und Sie die Löschung von personenbezogenen Daten, die beimBetreiber dieser Website gespeichert sind, veranlassen möchten, können Sie sich hierzu jederzeit an unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.</p>

<h3>Recht auf Einschränkung der Verarbeitung</h3>

<p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, von dem Verantwortlichen dieser Website die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
</p>
<ul>
    <li>Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen</li>
    <li>Die Verarbeitung ist unrechtmässig, die betroffene Person lehnt die Löschung der personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten</li>
    <li>Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen</li>
    <li>Die betroffene Person hat aus Gründen, die sich aus ihrer besonderen Situation ergeben, Widerspruch gegen die Verarbeitung eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen</li>
</ul>

<p>Sofern eine der oben genannten Voraussetzungen gegeben ist Sie die Einschränkung von personenbezogenen Daten, die beim Betreiber dieser Website gespeichert sind, verlangen möchten, können Sie sich hierzu jederzeit an unseren Datenschutzbeauftragten wenden. Der Datenschutzbeauftragte dieser Website wird die Einschränkung der Verarbeitung veranlassen.
</p>

<h3>Recht auf Datenübertragbarkeit</h3>
<p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie hat ausserdem das Recht, dass diese Daten bei Vorliegen der gesetzlichen Voraussetzungen einem anderen Verantwortlichen übermittelt werden.
</p>
<p>Ferner hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
</p>
<p>Zur Geltendmachung des Rechts auf Datenübertragbarkeit können Sie sich jederzeit an den vom Betreiber dieser Website bestellten Datenschutzbeauftragten wenden.</p>

<h3>Recht auf Widerspruch</h3>

<p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, Widerspruch einzulegen.
</p>
<p>Der Betreiber dieser Website verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
</p>
<p>Zur Ausübung des Rechts auf Widerspruch können Sie sich direkt an den Datenschutzbeauftragten dieser Website wenden.
</p>

<h3>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
</h3>
<p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das Recht, eine abgegebene Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
</p>
<p>Möchten Sie Ihr Recht auf Widerruf einer Einwilligung geltend machen, können Sie sich hierzu jederzeit an unseren Datenschutzbeauftragten wenden.
</p>


<h2>Verwendung von Adobe Fonts</h2>
<p>Wir setzen Adobe Fonts zur visuellen Gestaltung unserer Website ein. Adobe Fonts ist ein Dienst der Adobe Systems Incorporated, 345 Park Avenue, San Jose, CA 95110-2704, USA (Adobe), der uns den Zugriff auf eine Schriftartenbibliothek gewährt. Zur Einbindung der von uns benutzten Schriftarten, muss Ihr Browser eine Verbindung zu einem Server von Adobe in den USA aufbauen und die für unsere Website benötigte Schriftart herunterladen. Adobe erhält hierdurch die Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde. Weitere Informationen zu Adobe Fonts, finden Sie in den Datenschutzhinweisen von Adobe, die Sie hier abrufen können: Adobe Fonts
</p>

<h2>Email-Kontaktaufnahme</h2>
<p>
    Wir bieten Besuchern der Website die Möglichkeit mit uns über Email in Kontakt zu treten. Hierbei verarbeiten wir die gesendeten Daten zum Zwecke der Zimmer-Reservierung.
</p>

<h2>Änderungen</h2>
<p>Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise informieren.
</p>

<h2>Fragen an den Datenschutzbeauftragten</h2>
<p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz zu Beginn der Datenschutzerklärung aufgeführten, verantwortlichen Person in unserer Organisation.
</p>
<p>Zürich, 11.10.2020s<br />
Quelle: SwissAnwalt
</p>
</div>
</Default>

export default Datenschutz;